.firstl{
    flex: 1 1 0%;
}
.gen{
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.text-dark{
    color: black;
}
.gen-child{
    flex:1 1 0%;
}
.firstr{
    flex: 1 1 0%;
}
.p-header{
    color: black;
    font-weight: 500;
    font-size: larger;
}
.header{
    font-size: 65px;
   color: rgb(2, 12, 34); 
}
.bg-white{
background-color: white !important; 
}
 .vision{
    
    text-align: center;

}
.sourceimage{
    min-width: 300px;
    height:350px;
}
.editorial-button{
    background-color: black;
    width:150px;
    font-size: 19px;
    border-radius: 1px;
    color: white;
    border: 1px white;
}
.editorial-button:hover{
    opacity: 0.8;
}
.header-button{
    background-color: black;
    width:175px;
    font-size: 19px;
    border-radius: 8px;
    color: white;
    border: 2px white;
}
.header-button:hover{
    opacity: 0.8;
}
 .started-button{
background: linear-gradient(157157.81deg, rgb(14, 72, 222) 5.2%, rgb(3,22,65) 75.3%);
    width:160px;
font-size: 19px;
border-radius: 4px;
color: white;
border: none;
} 
.started-button:hover{
    opacity: 0.9;
}

.bg{
    background-image: url("/public/assets/pexels-artempodrez-7233130.jpg");
    background-size: cover;
}


.start{
    border-radius:15px;
    background:linear-gradient(157157.81deg, rgb(14, 72, 222) 5.2%, rgb(3,22,65) 75.3%);
    box-shadow: 0px 20px 100px -10px rgba(66,71,91,0.1);
}
@media screen and (max-width:600px){
h2{
font-weight: 500
!important;
}
.talign{
    text-align: center;
}
.clients{
    margin-left: 0rem !important;
}
h4{
font-size: 15px
!important;
}
h3{
    font-size: 30px
    !important;
}

}