
body{
  background-color:#F8FAFC;
overflow-x: hidden !important;
}
.brand{
  cursor:pointer;
}

a{
  text-decoration: none !important;
}
.text-gradient {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(157157.81deg, rgb(14, 72, 222) 5.2%, rgb(3,22,65) 75.3%);
  -webkit-background-clip: text;
  background-clip: text;
}
.dropdown-menu {
background-color: rgb(3,22,65) !important;;
}
.big-text{
  color: rgb(3,22,65) !important;
}
.logo{
  height: 50px;
width: 45px;
}
.logo2{
  height: 150px;
  width: 130px;
}

.p{
  font-weight: 400;
  font-size: larger;
}
 .primary1{
  --tw-bg-opacity: 1;
  background-color: rgb(0 4 15/var(--tw-bg-opacity)); 
 }
 .primary2{
  --tw-bg-opacity: 1;
  background-color:rgb(14, 46, 116); 
 }
.primary {
  --tw-bg-opacity: 1;
  background-color: rgb(3,22,65) !important;
}
.navbar-collapse{
  justify-content: end;
}
.navbar{
  font-size: 20px;
padding-bottom: 0.2 rem !important;
padding-top: 0.2 rem !important;
}
.nav-link{
color: white !important;}
.pa{
  font-size: x-large;
}
.brand-span{
  margin-bottom: 0px !important;
}
h1{
  font-size: 50px;
}
@media screen and (max-width:600px){
  h1{
    font-weight: 500
    !important;
      font-size: 52px
      !important;
    }
    .p{
      font-weight: 300
      !important;
      width: 100%
      !important;
      font-size: large
      !important;
    }
    .pa{
      font-size: large;
    }
 .get-started{
   width:85px
   !important;
   height:85px
   !important;
 }         
}