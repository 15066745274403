.site-footer
{
  padding:45px 0 20px;
  font-size:15px;
  line-height:24px;
  color:#737373;

width:100%;
}

.container{
    text-align: center;
}

.site-footer hr
{
  border-top-color:#bbb;
  opacity:0.5
}
.site-footer hr.small
{
  margin:20px 0
}

.site-footer h1
{
  color:#fff;
  font-size:30px;
  text-transform:uppercase;
  margin:5px;
  letter-spacing:2px
}
.site-footer h6{
    color:#fff;
  font-size:16px;
  text-transform:uppercase;
  margin-top:5px;
  letter-spacing:2px

}
.site-footer a
{
  color:#737373;
}

.footer-links
{
  padding-left:0;
  list-style:none;
  
}
.footer-links li
{
  display:block
}
.footer-links a
{
    text-decoration: none
    !important;
    color:#737373
}
a:hover{
  /* color: rgb(4, 27, 75); */
  color: rgb(30, 80, 187);
}
.footer-links.inline li
{
  display:inline-block
}
.site-footer .social-icons
{
  text-align:right
}
.site-footer .social-icons a
{
  width:40px;
  height:40px;
  line-height:40px;
  margin-left:6px;
  margin-right:0;
  border-radius:100%;
}
.copyright-text
{
  margin:0
}

@media (max-width:767px)
{
  .site-footer
  {
    padding-bottom:0
  }
  .site-footer .copyright-text,.site-footer .social-icons
  {
    text-align:center
  }
}
.social-icons
{
  padding-left:0;
  margin-bottom:0;
  list-style:none
}
.social-icons li
{
  display:inline-block;
  margin-bottom:4px
}
.social-icons li.title
{
  margin-right:15px;
  text-transform:uppercase;
  color:#96a2b2;
  font-weight:700;
  font-size:13px
}
.social-icons a{
  background-color:#eceeef;
  color:#818a91;
  font-size:16px;
  display:inline-block;
  line-height:44px;
  width:44px;
  height:44px;
  text-align:center;
  margin-right:8px;
  border-radius:100%;
  -webkit-transition:all .2s linear;
  -o-transition:all .2s linear;
  transition:all .2s linear
}
.social-icons.size-sm a
{
  line-height:34px;
  height:34px;
  width:34px;
  font-size:14px
}

@media (max-width:767px)
{
  .social-icons li.title
  {
    display:block;
    margin-right:0;
    font-weight:600
  }
}